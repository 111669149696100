import * as Sentry from '@sentry/react';
import { HydratedRouter } from 'react-router/dom';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';

Sentry.init( {
  dsn: 'https://20be5953338c4955e352bc071c59e7fb@o4505040793567232.ingest.us.sentry.io/4506920856715264',
  tracesSampleRate: 0.1,
  environment: import.meta.env.NODE_ENV
} )

startTransition( () => {
  hydrateRoot(
    document,
    <StrictMode>
      <HydratedRouter />
    </StrictMode>
  );
} );
